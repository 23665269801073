import axios from '@/api/http';
import base from '@/api/base';

const address = {

  //POST iotss/userAddress/queryAddressPage 请求地址记录
  getAddressList(data) {
    return axios.post(`${base.baseUrl}/iotss/userAddress/queryAddressPage?pageNo=${data.pageNo}&pageSize=${data.pageSize}`, data);
    // return axios.post(`${base.baseUrl}/clinic/patientaddress/page`, data);
  },
  //POST /iotss/userAddress/saveAddress 新增地址
  addAddress(data) {
    return axios.post(`${base.baseUrl}/iotss/userAddress/saveAddress`, data);
    // return axios.post(`${base.baseUrl}/clinic/patientaddress/savePatientAddress`,data);
  },
  //POST /iotss/userAddress/removeByIds 删除地址
  deleteAddress(ids) {
    return axios.post(`${base.baseUrl}/iotss/userAddress/removeByIds?ids=${ids}`);
    // return axios.delete(`${base.baseUrl}/clinic/patientaddress/remove/${id}`);
  },
  //POST /iotss/userAddress/setDefaultAddress 设置默认地址
  setDefaultAddress(data) {
    return axios.post(`${base.baseUrl}/iotss/userAddress/setDefaultAddress`, data);
    // return axios.post(`${base.baseUrl}/clinic/patientaddress/setDefaultAddress`,data);
  },
  //POST /iotss/userAddress/getDefaultAddress 获取默认地址
  getDefaultAddress(data) {
    return axios.post(`${base.baseUrl}/iotss/userAddress/getDefaultAddress?userId=${data.userId}`, data);
    // return axios.post(`${base.baseUrl}/clinic/patientaddress/pageOne`,data);
  },

  getAddressListByDistance(data) {
    return axios.post(`${base.baseUrl}/iotss/userAddress/getAddressListByDistance?userId=${data.userId}&distance=${data.deliveryDistance||data.distance}&longitude=${data.longitude}&latitude=${data.latitude}`);
    // return axios.post(`${base.baseUrl}/clinic/patientaddress/pageOne`,data);
  },
  // /iotss/userAddress/getById/${parms.addressId}
  getAddressList_ByAddressId_FormPrescription(data) {
    return axios.get(`${base.baseUrl}/iotss/userAddress/getById/${data.addressId}`);
  },

}


export default address;
