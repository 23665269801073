import axios from '@/api/http';
import base from '@/api/base';
const consult = {
	//POST /clinic/hospitalDoctor/queryDoctorPage 分页）查询医师列表信息------yzg
    queryDoctorPage(params) {
        return axios.post(`${base.baseUrl}/clinic/hospitalDoctor/queryDoctorPagePub`, params);
    },
	//POST /clinic/department/queryDepartmentPage PC---（分页）查询科室列表------ygt
    queryDepartmentPage(params) {
        return axios.post(`${base.baseUrl}/clinic/department/queryDepartmentPage`, params);
    },
	//GET /clinic/hospitalDoctor/info/{id} 查询医师详情
    queryHospitalDoctorInfo(params) {
        return axios.get(`${base.baseUrl}/clinic/hospitalDoctor/info/${params.id}?needRoomId=${params.needRoomId}`);
    },
  //GET /clinic/hospitalDoctor/info/{id} 查询医师详情
  getDoctorInfoByUserId(params) {
      return axios.get(`${base.baseUrl}/clinic/hospitalDoctor/getDoctorInfoByUserId?doctorUserId=${params.id}`);
    },

  /*
    *--预约问诊--
    * /clinic/doctorConfig/queryByDoctorId 获取医生的接诊配置
    * /clinic/doctorConfig/queryClinicCycle 查询医师坐诊情况
    * /clinic/doctorConfig/queryDayClinic 查询医师某天的上/下午的剩余接诊量
    * /clinic/doctorConfig/queryHourClinic 查询医师上/下午的每个时段剩余接诊量
    * /clinicWaiting/getPersonalRecord 我的预约——根据状态获取个人候诊记录
    * /clinic/clinicWaiting/refund 候诊用户取消预约
    *
  */
  // 获取医生的接诊配置
    queryDoctorConfig(data) {
      return axios.get(`${base.baseUrl}/clinic/doctorConfig/queryByDoctorId?doctorId=${data.doctorId}`)
    },
  // 我的预约
  getPersonalRecord(data) {
    return axios.post(`${base.baseUrl}/clinic/clinicWaiting/getPersonalRecord`, data)
  },
  // 候诊用户取消预约
  clinicWaitingRefund(data) {
    return axios.get(`${base.baseUrl}/clinic/clinicWaiting/refund?id=${data.id}&refundReason=${data.refundReason}`)
  },
  // 查询医师坐诊情况
    queryDoctorClinicCycle(data) {
      return axios.post(`${base.baseUrl}/clinic/doctorConfig/queryClinicCycle`, data)
    },
  // 查询医师某天的上/下午的剩余接诊量
    queryDoctorDayClinic(data) {
      return axios.post(`${base.baseUrl}/clinic/doctorConfig/queryDayClinic`, data)
    },
  // 查询医师上/下午的每个时段剩余接诊量
    queryDoctorHourClinic(data) {
      return axios.post(`${base.baseUrl}/clinic/doctorConfig/queryHourClinic`, data)
    },
  // 医生详情-在线问诊
    getDoctorOnlineClinicDetail(data) {
      return axios.post(`${base.baseUrl}/clinic/hospitalDoctor/getDoctorOnlineClinicDetail`, data)
    },
  //获取关注列表
    followList(data){
      return axios.post(`${base.baseUrl}/clinic/user/follow/list`, data)
    },
  //点击关注
    setFollow(data){
      return axios.get(`${base.baseUrl}/clinic/user/follow?userId=${data.userId}&doctorId=${data.doctorId}`)
    },
  //取消关注
    cancelFollow(data){
      return axios.get(`${base.baseUrl}/clinic/user/follow/cancel?userId=${data.userId}&doctorId=${data.doctorId}`)
    },


	//POST /clinic/patient/androidMallAddOrUpdate 添加关联患者
    androidMallAddOrUpdate(params) {
        return axios.post(`${base.baseUrl}/clinic/patient/androidMallAddOrUpdate`, params);
    },
	//POST /clinic/patient/selectByPrimaryKey/{id} 添加关联患者
    selectByPrimaryKey(params) {
        return axios.post(`${base.baseUrl}/clinic/patient/selectByPrimaryKey/${params.id}`, params);
    },

	//POST /clinic/doctorClinic/unifiedOrderNew new common api---统一下单---ygt i love 健康 用
    doctorClinicUnifiedOrderNew(params) {
        return axios.post(`${base.baseUrl}/clinic/doctorClinic/unifiedOrderNew`, params);
    },
  //POST /clinic/unifiedOrder/purchaseMedicines 购药下单
  unifiedOrderPurchaseMedicines(params) {
    return axios.post(`${base.baseUrl}/clinic/unifiedOrder/purchaseMedicines`, params);
  },
  //POST /clinic/unifiedOrder/consultationCosts 问诊下单
  unifiedOrderConsultationCosts(params) {
    return axios.post(`${base.baseUrl}/clinic/unifiedOrder/consultationCosts`, params);
  },
  //POST /clinic/unifiedOrder/purchaseMedicines/payment 购药下单支付
  unifiedOrderPurchaseMedicinesPayment(params) {
    return axios.post(`${base.baseUrl}/clinic/unifiedOrder/purchaseMedicines/payment`, params);
  },
  //POST /clinic/unifiedOrder/consultationCosts/payment 问诊下单支付
  unifiedOrderConsultationCostsPayment(params) {
    return axios.post(`${base.baseUrl}/clinic/unifiedOrder/consultationCosts/payment`, params);
  },
	//POST /clinic/medicine/unifiedOrderBuyMedicineNewMultiple 买药统一下单------ 问诊室用的(支持单次返回多个支付信息), status为20100时患者没有绑定手机号码, status为20000时库存不足,返回的data为List<GoodsNeedStockBO(库存不足的商品详情)>
  medicineUnifiedOrderBuyMedicineNewMultiple(params) {
      return axios.post(`${base.baseUrl}/clinic/medicine/unifiedOrderBuyMedicineNewMultiple`, params);
  },
  //POST /clinic/unifiedOrder/queryOrderPayStatus/${orderId} 买药下单支付 轮询回调
  queryOrderPayStatus(orderId) {
      return axios.post(`${base.baseUrl}/clinic/unifiedOrder/queryOrderPayStatus/${orderId}`);
  },
  //POST /clinic/unifiedOrder/queryClinicOrderPayStatus/${orderId} 问诊支付 轮询回调
  queryClinicOrderPayStatus(orderId) {
    return axios.post(`${base.baseUrl}/clinic/unifiedOrder/queryClinicOrderPayStatus/${orderId}`);
  },
  //GET /clinic/doctorClinic/queryInProgressClinic/{userId} 获取当前用户下患者进行中的问诊记录-PC
  queryInProgressClinic(params) {
      return axios.get(`${base.baseUrl}/clinic/doctorClinic/queryInProgressClinic/${params.userId}`, params);
  },

	//GET /clinic/tencentRtc/createUserSig/{userId} 获取腾讯云签名
	createUserSig(userId) {
	    return axios.get(`${base.baseUrl}/clinic/tencentRtc/createUserSig/${userId}`);
	},
	//GET /clinic/tencentRtc/getSdkAppId 获取腾讯云SdkAppId
	getSdkAppId(userId) {
	    return axios.get(`${base.baseUrl}/clinic/tencentRtc/getSdkAppId`);
	},
	//POST /clinic/doctorClinic/saveDoctorClinic 修改问诊信息 此接口不允许用来结束问诊单(请使用doctorClinic-closeClinic)
    saveDoctorClinic(params) {
        return axios.post(`${base.baseUrl}/clinic/doctorClinic/saveDoctorClinic`, params);
    },
	//POST /clinic/doctorClinic/closeClinic 结束问诊单(请使用doctorClinic-closeClinic)
    closeClinic(params) {
        return axios.post(`${base.baseUrl}/clinic/doctorClinic/closeClinic?userId=${params.userId}`, params);
    },
	//POST /clinic/doctorClinicPrescription/queryDoctorClinicPrescriptionPage （分页）查询处方单列表信息------yzg
    queryDoctorClinicPrescriptionPage(params) {
        return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/queryDoctorClinicPrescriptionPage`, params);
    },
  //POST /clinic/doctorClinicPrescription/queryDoctorClinicPrescriptionBySerialNo 查询处方单详情信息------zss
  queryDoctorClinicPrescriptionBySerialNo(params) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/queryDoctorClinicPrescriptionBySerialNo?serialNo=${params.serialNo}`,params);
  },
  //通过处方单号获取处方单信息-购药时
  buyDoctorClinicPrescriptionBySerialNo(query) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/buyDoctorClinicPrescriptionBySerialNo?serialNo=${query.serialNo}&latitude=${query.latitude}&longitude=${query.longitude}`);
  },

	//POST /clinic/doctorClinic/queryDoctorClinicPage （分页）查询问诊记录列表------ygt
  queryDoctorClinicPage(params) {
      return axios.post(`${base.baseUrl}/clinic/doctorClinic/queryDoctorClinicPage`, params);
  },
  //POST /clinic/doctorClinic/queryUserDoctorClinicPage （分页）查询 我的 问诊记录列表------ygt
  queryUserDoctorClinicPage(params) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinic/queryUserDoctorClinicPage`, params);
  },


	// 购药箱
	//POST /clinic/medicine/buy/multi 患者购药(批量添加商品到购物车)
    medicineBuyMulti(params) {
        return axios.post(`${base.baseUrl}/clinic/medicine/buy/multi`, params);
    },
	// 处方笺添加到购药箱
	//GET /clinic/medicine/buy/{clientId}/{patientId} 患者处方单购药或购物车添加药品,弃用请使用'用户或患者购物车添加药品'
    medicineBuyPrescription(params) {
        return axios.get(`${base.baseUrl}/clinic/medicine/buy/${params.clientId}/${params.patientId}?serialNo=${params.serialNo}`);
    },
	//GET /clinic/medicine/getGoodsTargetGroup/{clientId} 获取购药箱的数据(通过target分类)
	getGoodsTargetGroup(params) {
	    return axios.get(`${base.baseUrl}/clinic/medicine/getGoodsTargetGroup/${params.clientId}`,params);
	},

	// 图文问诊
	//POST /clinic/clinicImgTxtRecord/queryPage 查询聊天记录
	clinicImgTxtRecordWithPage(params) {
	    return axios.post(`${base.baseUrl}/clinic/clinicImgTxtRecord/queryPage?pageSize=${params.pageSize}&pageNo=${params.pageNo}&orderBy=${params.orderBy}&type=${params.type}`,params);
	},
	//GET /doctorClinicTxt/queryOne/{id} 查询患者详细描述(通过id)
	getdescribeopen(params){
		return axios.get(`${base.baseUrl}/clinic/doctorClinicTxt/queryOne/${params}`);
	},

	// 审核
	//POST /clinic/hospitalPharmacist/queryAssignedPharmacist 查询可分配药师
	queryAssignedPharmacist(params) {
		return axios.post(`${base.baseUrl}/clinic/hospitalPharmacist/queryAssignedPharmacist`, params);
	},
	//POST /clinic/patient/queryPatientByUserId/{userId} 获取患者信息
	queryPatientByUserId(userId) {
		return axios.post(`${base.baseUrl}/clinic/patient/queryPatientByUserId/${userId}`);
	},
	//POST /clinic/doctorClinicPrescription/userConfirm 用户确认处方
	userConfirmPrescription(params) {
		return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/userConfirm`, params);
	},
	//POST /clinic/patient/list 查询当前用户下的患者列表,查询的是当前登录用户的,不能指定用户id
	userforPatient(params) {
		return axios.post(`${base.baseUrl}/clinic/patient/list`, params);
	},
  queryDoctorUserInProgressClinic(params) {
    return axios.get(`${base.baseUrl}/clinic/doctorClinic/queryDoctorUserInProgressClinic?doctorUserId=${params.doctorUserId}&userId=${params.userId}`);
  },
  queryDoctorUserInProgressClinic_new(params) {
    return axios.get(`${base.baseUrl}/clinic/doctorClinic/queryDoctorUserClinicStatus?doctorUserId=${params.doctorUserId}&userId=${params.userId}`);
  },
  //用户端查看消息列表-对话详情
  queryPageByUser(query, body) {
    return axios.post(`${base.baseUrl}/clinic/clinicImgTxtRecord/queryPageByUser?pageNo=${query.pageNo}&pageSize=${query.pageSize}&type=${query.type}`, body);
  },
  //处方单购药下单
  prescription_confirmOrder(body) {
    return axios.post(`${base.baseUrl}/clinic/mall/prescription/confirmOrder`, body);
  },


  //--------------------------------------------
  //POST /clinic/doctorClinicPrescription/userConfirmAddressInfo 医生开处方-用户确认收货地址
  userConfirmAddressInfo(params) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/userConfirmAddressInfo`, params);
  },
  //POST 挂断通讯
  hangUpClinic(params) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinic/hangUpClinic`, params);
  },

}
export default consult;
