import axios from '@/api/http';
import base from '@/api/base';

const mall ={

  //GET /clinic/medicinalColumn/queryMedicinalColumn 查询药品栏目
  queryMedicinalColumn(params){
    return axios.get(`${base.baseUrl}/iotss/medicinalColumn/queryMedicinalColumn`, params);
  },
  //POST /iotss/mall/selectHomePageInfo 查询首页药店
  selectHomePageInfo(params){
    // return axios.post(`${base.baseUrl}/iotss/goods/selectHomePageInfo`, params);
    return axios.post(`${base.baseUrl}/iotss/mall/selectHomePageInfo`, params);
  },
  //POST /iotss/mall/selectDrugsDetails 查询药品详情
  selectGoodsDetails(params){
    // return axios.post(`${base.baseUrl}/iotss/goods/selectGoodsDetails`, params);
    return axios.post(`${base.baseUrl}/iotss/mall/selectDrugsDetails`, params);
  },
  //GET 查询接受app推送且免费在线的医生
  getFreeAndAcceptAppOrderDoctorList(){
    return axios.get(`${base.baseUrl}/clinic/hospitalDoctor/getFreeAndAcceptAppOrderDoctorList`);
  },
  //GET 获取商品库存
  getInventoryByRefGoodsId(type, refDrugId){
    // return axios.get(`${base.baseUrl}/iotss/storeGoods/getInventoryByRefGoodsId/${type}/${refGoodsId}`);
    return axios.get(`${base.baseUrl}/iotss/storeDrugs/getInventoryByRefDrugId/${type}/${refDrugId}`);
  },
  //GET /iotss/tenant/store/findStoreInfoById/id 查询药店详情
  findStoreInfoById(params){
    return axios.get(`${base.baseUrl}/iotss/tenant/store/findStoreInfoById/${params.id}`, params);
  },
  //GET /iotss/tenant/store/findStoreInfoByUserId/id 查询门店信息 (包含门店关注信息)
  findStoreInfoByUserId(params){
    return axios.get(`${base.baseUrl}/iotss/tenant/store/findStoreInfoByUserId/${params.id}?userId=${params.userId}`);
  },

  /**
   * 门店收藏
   */
  //post 用户收藏门店列表 /iotss/user/store/collection/list
  collectionList(params){
    return axios.post(`${base.baseUrl}/iotss/user/store/collection/list`, params);
  },
  //get 用户收藏门店 /iotss/user/store/collection
  setCollection(data){
    return axios.get(`${base.baseUrl}/iotss/user/store/collection?userId=${data.userId}&storeId=${data.storeId}`);
  },
  //get 用户取消门店 /iotss/user/store/collection/cancel?userId=${data.userId}&storeId=${data.storeId}
  cancelCollection(data){
    return axios.get(`${base.baseUrl}/iotss/user/store/collection/cancel?userId=${data.userId}&storeId=${data.storeId}`);
  },
  //GET /iotss/tenant/store/findGoodsListByGoodsName 门店搜索
  findGoodsListByGoodsName(params){
    // return axios.get(`${base.baseUrl}/iotss/tenant/store/findGoodsListByGoodsName?storeId=${params.storeId}&goodsName=${params.goodsName}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`);
    return axios.get(`${base.baseUrl}/iotss/tenant/store/findDrugsListByDrugName?storeId=${params.storeId}&drugName=${params.goodsName}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`);
  },
  //GET /iotss/storeGoods/selectGoodsCategoryList/{storeId} 查询门店下的商品分类
  selectGoodsCategoryList(storeId){
    // return axios.get(`${base.baseUrl}/iotss/storeGoods/selectGoodsCategoryList/${storeId}`);
    return axios.get(`${base.baseUrl}/iotss/storeDrugs/selectDrugsCategoryList/${storeId}`);
  },
  //GET /iotss/storeDrugs/selectDrugsListByCategoryId/{storeId}/{categoryId} 根据类目id查询药店下药品列表
  selectGoodsListByCategoryId(params){
    return axios.get(`${base.baseUrl}/iotss/storeDrugs/selectDrugsListByCategoryId/${params.storeId}?categoryId=${params.categoryId}`);
  },
  //GET /deviceGrid/medicine/selectDrugsListByCategoryId/{deviceId}/{categoryId} 根据分类ID查询门设备的商品列表
  selectGoodsListByCategoryId_deviceId(params){
    return axios.get(`${base.baseUrl}/iotss/deviceGrid/medicine/selectDrugsListByCategoryId/${params.deviceId}?categoryId=${params.categoryId}`);
  },
  //GET /iotss/storeDevice/mall/selectListDeviceDistanceSort/{storeId} 请求门店下设备信息
  selectListDeviceDistanceSort(params){
    return axios.get(`${base.baseUrl}/iotss/storeDevice/mall/selectListDeviceDistanceSort/${params.storeId}?latitude=${params.latitude}&longitude=${params.longitude}`);
  },
  //GET /iotss/devicegrid/selectGoodsCategoryList/{deviceId} 查询设备下的商品分类
  selectGoodsCategoryListByDeviceId(params){
    return axios.get(`${base.baseUrl}/iotss/deviceGrid/medicine/selectDrugsCategoryByMedicine?deviceId=${params.deviceId}&drugsType=${params.drugsType}`);
  },
  //GET /iotss/devicegrid/selectGoodsListByCategoryId/{categoryId} 根据分类ID查询门店设备的商品列表
  selectGoodsListByCategoryIdByDeviceId(params){
    return axios.get(`${base.baseUrl}/iotss/deviceGrid/medicine/selectDrugsListByCategoryId/${params.deviceId}?categoryId=${params.categoryId}`);
  },
  //POST /iotss/shopCartGoods/addGoods 添加商品到购物车
  shopCartGoodsToAddGoods(params){
    // return axios.post(`${base.baseUrl}/iotss/shopCartGoods/addGoods`,params);
    return axios.post(`${base.baseUrl}/iotss/shopCartDrugs/addDrugs`,params);
  },
  //POST /iotss/shopCartGoods/addGoodsBatch 批量添加商品到购物车----再次购买场景
  addGoodsBatch(params){
    // return axios.post(`${base.baseUrl}/iotss/shopCartGoods/addGoodsBatch`,params);
    return axios.post(`${base.baseUrl}/iotss/shopCartDrugs/addDrugsBatch`,params);
  },
  //POST /iotss/shopCartGoods/selectByUserId 查询购物车商品（根据userId）
  shopCartGoodsToSelectByUserId(params){
    // return axios.post(`${base.baseUrl}/iotss/shopCartGoods/selectByUserId?userId=${params.userId}&storeId=${params.storeId}`,params);
    return axios.post(`${base.baseUrl}/iotss/shopCartDrugs/selectByUserId?userId=${params.userId}&storeId=${params.storeId}`,params);
  },
  //POST /iotss/shopCartDrugs/calculatedDrugsNum 查询购物车商品數量（根据userId）
  calculatedDrugsNum(params){
    return axios.post(`${base.baseUrl}/iotss/shopCartDrugs/calculatedDrugsNum?userId=${params.userId}&storeId=${params.storeId}`,params);
  },
  //POST /iotss/shopCartGoods/deleteByIds 删除购物车商品
  shopCartGoodsToDeleteByIds(params){
    // return axios.post(`${base.baseUrl}/iotss/shopCartGoods/deleteByIds`,params);
    return axios.post(`${base.baseUrl}/iotss/shopCartDrugs/deleteByIds`,params);
  },
  //POST /iotss/storeGoods/getInventoryByRefGoodsIds 获取商品库存-批量（购物车提交结算使用）
  getInventoryByRefGoodsIds(params){
    // return axios.post(`${base.baseUrl}/iotss/storeGoods/getInventoryByRefGoodsIds`,params);
    return axios.post(`${base.baseUrl}/iotss/storeDrugs/getInventoryByRefDrugIds`,params);
  },

  /**
   * Order-订单相关
   */
  //POST /iotss/mall/order/page 商城服务——订单查询
  queryOrderPage(params){
    return axios.post(`${base.baseUrl}/iotss/mall/order/page`,params);
  },
  //POST /iotss/mall/oneOrderDetail 查询单个订单详情
  queryOrderOneOrderDetail(params){
    return axios.post(`${base.baseUrl}/iotss/mall/order/oneOrderDetail`,params);
  },
  // 确认订单-根据经纬度获取距离
  getDistanceByRefGoodsIds (query, body)  {
    // return axios.post(`${base.baseUrl}/iotss/tenant/store/getDistanceByRefGoodsIds?longitude=${query.longitude}&latitude=${query.latitude}`,body)
    return axios.post(`${base.baseUrl}/iotss/tenant/store/getDistanceByRefDrugIds?longitude=${query.longitude}&latitude=${query.latitude}`,body)
  },
  // 查询单个订单详情
  oneOrderDetail (body){
    return axios.post(`${base.baseUrl}/iotss/mall/order/oneOrderDetail`,body)
  },
  // 取消订单
  mallOrderCancel (body){
    return axios.post(`${base.baseUrl}/clinic/mall/mallOrderCancel`,body)
  },
  // 逻辑删除订单
  delete_id (parms){
    return axios.delete(`${base.baseUrl}/iotss/mall/order/delete/${parms.orderId}`)
  },
  //商城订单获取预生成订单ID
  getPreOrderId(data){
    return axios.post(`${base.baseUrl}/clinic/mall/getPreOrderId`, data)
  },
  //商城订单提交订单
  confirmOrder(data){
    return axios.post(`${base.baseUrl}/clinic/mall/confirmOrder`, data)
  },
  //商城订单支付
  mallPayOrder(body){
    return axios.post(`${base.baseUrl}/clinic/mall/mallPayOrder`, body)
  },
  //支付状态
  queryOrderPayStatus(orderId){
    return axios.post(`${base.baseUrl}/clinic/mall/queryOrderPayStatus/${orderId}`)
  },
  /**
   * comment 评论相关
   */
  //获取商品评论列表
  queryComment(data){
    return axios.post(`${base.baseUrl}/iotss/stGoodsComment/queryComment`, data)
  },
  //添加评论
  setGoodsComment_submit(data){
    return axios.post(`${base.baseUrl}/iotss/stGoodsComment/submit`, data)
  },
  /**
   * invoice 发票相关
    */
  // 提交发票信息申请
  postInvoice(params){
     return axios.post(`${base.baseUrl}/iotss/invoice/apply`, params)
  },
  // 获取发票信息
  getfp(params){
    return axios.post(`${base.baseUrl}/iotss/invoice/query`, {
      pageNo: 1,
      pageSize: 10,
      orderId: params
    })
  },
  // 撤销发票申请
  repealFp(params){
    return axios.get(`${base.baseUrl}/iotss/invoice/withdraw/${params}`)
  },
  // 更新发票申请
  updateFp(params){
    return axios.post(`${base.baseUrl}/iotss/invoice/update`,params)
  },

  /**
   * inquiries 门店咨询
   * @param query
   * @returns {Promise<axios.AxiosResponse<any>>}
   * @constructor
   */
  // 进入门店咨询+咨询购药
  Inquiries(query){
    return axios.post(`${base.baseUrl}/clinic/inquiries/goto/store/Inquiries?storeId=${query.storeId}&userId=${query.userId}`,query)
  },
  // 门店咨询--用户--发送咨询药品
  sendInquiriesDrugs(params){
    return axios.post(`${base.baseUrl}/clinic/inquiries/user/sendInquiriesDrugs`,params)
  },


}


export default mall;
