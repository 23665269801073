import axios from '@/api/http';
import base from '@/api/base';

//获取腾讯云SdkAppId
export function getSdkAppId(data){
  return axios.get(`${base.baseUrl}/clinic/tencentRtc/getSdkAppId`);
}
//IM登录密钥
export function createUserSig(params) {
  return axios.get(`${base.baseUrl}/clinic/tencentRtc/generateUserSig/${params.userId}`);
}
