import axios from "@/api/http"; // 导入http中创建的axios实例
import base from "@/api/base"; // 导入接口域名列表
const upload = {
	//POST /iotss/file/user/upload 用户文件上传, 必须登录才可以使用
	loadFile(files) {
		return axios.post(`${base.baseUrl}/iotss/file/user/upload`, files);
	},
  //POST /iotss/file/minio/upload 默认文件上传-----new
	loadFile_Default(file) {
		return axios.post(`${base.baseUrl}/iotss/file/minio/upload`, file);
	},
  /**
   * loadFile_Default (TYPE) 默认文件上传
   * @param { TYPE } file
   * USER_AVATAR(1,"user/logo","用户头像"),
   * STORE(2,"store","门店相关图片"),
   * DOCTOR(3,"doctor","医师"),
   * PHARMACIST(4,"pharmacist","药师"),
   * DRUG(5,"drug","药品"),
   * ADVERT(6,"advert","广告"),
   * APK_PACKAGE(7,"apk","apk包管理"),
   * CERT(8,"cert","证书"),
   * DEVICE_LOG(9,"advice/log","设备日志"),
   * DEVICE_REPLENISH(10,"advice/replenish","设备补货图片"),
   * DEVICE_OUT(11,"advice/replenish","设备出货图片");
   * CLINIC(12,"clinic","问诊");
   */
};
export default upload;
