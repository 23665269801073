import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import base from '@/api/base'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  state: {
    username: sessionStorage.getItem('userName'),
    token: localStorage.getItem('office_web:token'),
    userId: sessionStorage.getItem('userId'),
    payforParams: null, //下单参数
    paymentParams: null, //支付参数
  },
  getters: {
    getPayfor: (state) => {
      return state.payforParams
    },
    getPayment: (state) => {
      return state.paymentParams
    },
    system_conversation: state => {
      let result = {}
      try {
        //取出system用户会话
        result = state.chat.allConversation.find(item => item.conversationID === base.IM_systemId)
      } catch (e) {

      }
      return result
    }
  },
  mutations: {
    setToken (state, data) {
      state.token = data
      localStorage.setItem('office_web:token', data)
    },
    setUserId (state, data) {
      state.userId = data
      sessionStorage.setItem('userId', data)
    },
    //清除token
    clearToken (state) {
      state.token = ''
      localStorage.removeItem('office_web:token')
    },
    setPayfor (state, data) {
      state.payforParams = data
    },
    setPayment (state, data) {
      state.paymentParams = data
    },
    //用于解决退出登录时，vuex中数据不消失的问题
    LOGOUT (){

    }
  },
  actions: {
    SET_Payfor ({ commit }, data) {
      commit('setPayfor', data)
    },
    SET_Payment ({ commit }, data) {
      commit('setPayment', data)
    },
    SET_LOGOUT({ commit }){
      commit('user/SET_user_info', {})
      //清除本地缓存，必须把'LOGOUT'命令放在最后
      commit('LOGOUT')
    }
  },
  modules,
  plugins: [
    createPersistedState({
      storage: window.localStorage, // 默认使用localStorage
      key: 'myStroage', // 存储的键名
      reducer (val) {
        return { //modules的模块名
          searchHistory: val.historySearch.searchHistory, //字段名:val.模块名.字段名
          user_info: val.user.user_info,
          location: val.user.location,
        }
      },
      filter: mutation => {
        // 当调用LOGOUT mutation时清除缓存
        if (mutation.type === 'LOGOUT') {
          console.log('当调用LOGOUT mutation时清除缓存:::')
          window.localStorage.clear();
          window.sessionStorage.clear();
          return false
        }else{
          return true
        }

      }
    }),
  ], // 添加插件
})
