import axios from '@/api/http';
import base from '@/api/base';
export default{
    //查询分类
    getsort(id){
    return axios.get(`${base.baseUrl}/iotss/goods/wzs/tenant/getCategories/${id}`);
    },
    //查询药品列表
    goodList(params){
        const { storeId } = params
        return axios.get(`${base.baseUrl}/iotss/storeGoods/wzsGoodsShow/page/${storeId}`,{params});
    },
    //查询药品详情
    getDetail(data){
        return axios.post(`${base.baseUrl}/iotss/goods/getGoodsDetails`,data);
    },
    //查询店铺列表
    getShopList(data){
        return axios.post(`${base.baseUrl}/iotss/tenant/store/page`,data);
    },
    //获取用户购物车数据
    getCartData(params){
        const { clientId,patientId  }=params
		if(patientId){
			return axios.get(`${base.baseUrl}/clinic/medicine/getGoodsTargetGroup/${clientId}/${patientId}`);
		}else{
			return axios.get(`${base.baseUrl}/clinic/medicine/getGoodsTargetGroup/${clientId}`);
		}
    },
    //向购物车添加商品
    shopcar(data){
        return axios.post(`${base.baseUrl}/clinic/medicine/buy/multi`,data);
    },
    //删除商品
    Remove(params){
        const {buyMedicineId,goodsId}=params
        return axios.get(`${base.baseUrl}/clinic/medicine/delete/${buyMedicineId}/${goodsId}`,{params});
    },
    //购物车商品数量增加
    Addshop(params){
        // const {clientId }=params
        return axios.post(`${base.baseUrl}/clinic/medicine/buyMedicineAddGoods`,params);
    },
    //购物车商品数量减少
    Delshop(params){
        const {buyMedicineId,goodsId}=params
        return axios.get(`${base.baseUrl}/clinic/medicine/reduce/${buyMedicineId}/${goodsId}`,{params});
    },
    //批量删除购物车数据
    clearAll(data){
        return axios.post(`${base.baseUrl}/clinic/medicine/batchDeleteGoods`,data);
    },
    //立即购买
    BuyNow(data){
        return axios.post(`${base.baseUrl}/clinic/medicine/addTemp`,data);
    },
    //查询立即购买的商品
    getBuyNow(data){
        return axios.post(`${base.baseUrl}/clinic/medicine/getGoodsTargetGroupById`,data);
    },
    //获取地址ID
    GetAddress(data){
        return axios.post(`${base.baseUrl}/iotss/userAddress/saveAddress`,data);
        // return axios.post(`${base.baseUrl}/clinic/patientaddress/savePatientAddress`,data);
    },
    //支付订单
    PayOrder(data){
        return axios.post(`${base.baseUrl}/clinic/medicine/unifiedOrderBuyMedicineNewMultiple`,data);
    }
}
