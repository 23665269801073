import axios from "@/api/http";
import base from "@/api/base";

const myInfo = {
  //POST /iotss/user/getById 查询用户信息---弃用
  // getUserInfoById(params){
  //   return axios.post(`${base.baseUrl}/iotss/user/getById?id=${params.userId}`);
  // },
  //POST /iotss/internal/user/getUserInfo 查询用户信息
  getUserInfoById(params) {
    return axios.post(
      `${base.baseUrl}/iotss/internal/user/getUserInfo?userId=${params.userId}&type=${params.type}`
    );
  },

  //POST /internal/user/updateUserBaseInfo/updateUserBaseInfo 修改个人信息
  updateUserBaseInfo(params) {
    return axios.post(
      `${base.baseUrl}/iotss/internal/user/updateUserBaseInfo`,
      params
    );
  },
  //POST /iotss/user/bindPhoneCheck 绑定手机号检查-(修改手机号检查
  bindPhoneCheck(params) {
    return axios.post(`${base.baseUrl}/iotss/user/bindPhoneCheck`, params);
  },
  //POST /iotss/user/bindPhone 绑定手机号-(修改手机号)
  bindPhone(params) {
    return axios.post(`${base.baseUrl}/iotss/user/bindPhone`, params);
  },
  //POST /iotss/user/oldPhoneCheck 修改手机号-旧手机号验证
  oldPhoneCheck(params) {
    return axios.post(`${base.baseUrl}/iotss/user/oldPhoneCheck`, params);
  },

  //POST /iotss/user/unBindWechat 解绑微信
  unBindWechat(params) {
    return axios.post(`${base.baseUrl}/iotss/user/unBindWechat`, params);
  },
  //POST /iotss/user/pc/bindWechatCheck 网页绑定微信号检查
  bindWechatCheck(params) {
    return axios.post(`${base.baseUrl}/iotss/user/pc/bindWechatCheck`, params);
  },
  //POST /iotss/user/pc/bindWechat 网页确认绑定微信号
  bindWechat(params) {
    return axios.post(`${base.baseUrl}/iotss/user/pc/bindWechat`, params);
  },
  //POST /iotss/user/mergeUserWechatData 执行合并微信数据
  mergeUserWechatData(params) {
    return axios.post(`${base.baseUrl}/iotss/user/mergeUserWechatData`, params);
  },

  //POST /iotss/user/updatePassword 修改密码
  updatePassword(params) {
    return axios.post(`${base.baseUrl}/iotss/user/updatePassword`, params);
  },
  //POST /iotss/user/settingPassword 设置密码
  settingPassword(params) {
    return axios.post(`${base.baseUrl}/iotss/user/settingPassword`, params);
  },
  //用户反馈
  save(params) {
    return axios.post(`${base.baseUrl}/iotss/advice/save`, params);
  },
};
export default myInfo;
