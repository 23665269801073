/* 全局mixin */
import { mapState } from 'vuex'
export default  {
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      user_info: state => state.user.user_info,
      app_location: state => state.user.location,
    })
  }
}
