//自定义消息类型
export default {
  callEnd: 'callEnd', //音视频通话挂断方发送
	goodsConsult: 'goodsConsult',//商品咨询
	patient_condition_description: 'patient_condition_description',//病情描述
  prescription: 'prescription',//处方单
  req_address: 'req_address',//收货地址
  user_send_drugs: 'user_send_drugs',//药品审核咨询-用户
  doctor_do_clinic: 'doctor_do_clinic',//临床咨询-医生
  doctor_info_clinic: 'doctor_info_clinic',//医生信息-医生
}
