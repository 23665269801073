import base from './base'
import eventHub from '@/utils/eventHub'
// 定义WebSocket对象
let  socketTask = null;
// 定义心跳相关变量
let heartBeatInterval = null;
//心跳发送内容
let heartBeatData = {
  sendType: "SEND_TYPE_ONE_TO_ONE",
  msgType: "CLIENT_HEARTBEAT",
  content: {}
};
let heartBeatFrequency = 5000; // 心跳频率（毫秒）
// 定义重连相关变量
let reconnectAttempts = 0;
let maxReconnectAttempts = 100; //重连次数
let reconnectInterval = 5000;
let lastHeartbeatTime = new Date().getTime();
let checkHeartInterval = null;
let reconnectTimer = null;
let isLogin = false;

// 创建连接
function connectWebSocket(payload,random) {
  // if (!localStorage.getItem('office_web:token')) return;//没有token则返回
  if (!base.wsUrl) {
    console.log('没有ws地址');
    return
  }
  // let token = localStorage.getItem('office_web:token').split("Bearer ")[1];
  let token = '';
  let base_url = base.wsUrl + token;
  if(payload && payload === 'login'){
    isLogin = true;
    base_url = base.loginWsUrl + '/' + random;
    // base_url = base.loginWsUrl + `?type=login&content=${random}`;
  }
  console.log('连接:url::', base_url);

  socketTask = new WebSocket(base_url);

  // 监听WebSocket连接打开事件
  socketTask.onopen = (function(event) {
    console.log("WebSocket连接已打开：");
    // 重置重连尝试次数
    reconnectAttempts = 0;
    // 开始心跳
    startHeartbeat();
    // 检测心跳
    checkHeartbeat();
  });

  // 监听WebSocket接收到服务器的消息事件
  socketTask.onmessage = (function(event) {
    // console.log("收到服务器内容：" + event.data);
    try {
      var valData = JSON.parse(event.data);
      if (valData.msgType === "WS_LOGOUT_MESSAGE") {
        //收到退出登录消息
        console.log("收到退出登录消息：");
        // on_LOGOUT_MESSAGE()
        //不关闭，会收不到消息
        closeWebSocket()
        return
      }
      if (valData.msgType !== "CLIENT_HEARTBEAT") { //排除心跳消息
        switch (valData.msgType) {
          case 'PATIENT_SEND_PAY_STATUS': //推送支付状态信息
            eventHub.$emit("socket-pay", valData)
            break;
          default:
            eventHub.$emit("socket-data", valData)
            break;
        }

      }
      // APP扫码登陆
      if(isLogin){
        console.log('App登录获取sessionID:::',valData)
        eventHub.$emit("login-message", valData)
      }
    } catch (e) {
      //TODO handle the exception
    }
    // 收到消息后更新心跳时间
    // lastHeartbeatTime = new Date().getTime();
  });

  // 监听WebSocket错误
  socketTask.onerror = (function(error) {
    console.error("WebSocket连接错误：" + JSON.stringify(error));
    // 停止心跳
    // stopHeartbeat();
    // reconnectWebSocket()
  });

  // 监听WebSocket连接关闭事件
  socketTask.onclose = (function(e) {
    console.log("WebSocket 已关闭：" + JSON.stringify(e));
    // 停止心跳
    try{
      if (e.code == 1000) {
        stopHeartbeat();
      }
     /*  else {
        reconnectWebSocket()
      } */
    }catch(e){
      //TODO handle the exception
    }
  });
}
// 心跳函数
function startHeartbeat() {
  heartBeatInterval = setInterval(() => {
    sendMessage(JSON.stringify(heartBeatData)).then(res => {
      // console.log('心跳消息发送成功::::');
      // 收到消息后更新心跳时间
      lastHeartbeatTime = new Date().getTime();
    })
  }, heartBeatFrequency);
}

// 停止心跳
function stopHeartbeat() {
  //停止心跳
  if (heartBeatInterval) {
    clearInterval(heartBeatInterval);
    heartBeatInterval = null;
  }
  //停止检测心跳
  if (checkHeartInterval) {
    clearInterval(checkHeartInterval);
    checkHeartInterval = null;
  }
}

// 发送消息函数（使用Promise）
function sendMessage(message) {
  return new Promise((resolve, reject) => {
    if (socketTask && socketTask.readyState === socketTask.OPEN) {
      socketTask.send(message);
      resolve(); // 发送成功时解决Promise
    } else {
      console.log("WebSocket未连接");
      reject(new Error("WebSocket未连接")); // 如果WebSocket未连接，拒绝Promise
    }
  });
}


// 关闭WebSocket连接
function closeWebSocket() {
  console.log('主动调用关闭socket')
  if (socketTask) {
    socketTask.close();
  }
  // 停止心跳
  stopHeartbeat();
}

// 重连WebSocket的函数
function reconnectWebSocket() {
  //函数防抖
  clearTimeout(reconnectTimer)
  reconnectTimer = setTimeout(()=>{
    if (reconnectAttempts < maxReconnectAttempts) {
      reconnectAttempts++;
      console.log("尝试重连WebSocket，次数：" + reconnectAttempts);
      // 关闭当前连接
      closeWebSocket();
      // 创建新的连接
      connectWebSocket();
    } else {
      console.log("已达到最大重连次数，停止尝试重连");
    }
  }, 2000)
}

// 检测心跳是否丢失的函数
function checkHeartbeat() {
  clearInterval(checkHeartInterval);
  // 假设我们期望每隔一定时间收到心跳响应
  checkHeartInterval = setInterval(() => {
    /* let currentTime = new Date().getTime();
     if (currentTime - lastHeartbeatTime > 2 * heartBeatFrequency) {
       // 如果超过两倍的心跳间隔没有收到心跳，认为连接已断开
       console.log("心跳丢失，尝试重连...");
       // reconnectWebSocket();
     } */

    if (socketTask.readyState === socketTask.OPEN) {
      //ws开启状态
    } else if (socketTask.readyState === socketTask.CONNECTING) {
      //  正在开启状态
    } else {
      // 未开启
      console.log(
        "没有心跳啦~",
        socketTask.readyState,
        socketTask.OPEN,
        socketTask.CONNECTING
      );
      reconnectWebSocket();
    }
  }, heartBeatFrequency);
}


export default {
  connectWebSocket,
  sendMessage,
  closeWebSocket,
};
