import axios from '@/api/http';
import base from '@/api/base';


const home ={

    // 请求医生列表
    queryDoctorPagePubUsing(data){
        return axios.post(`${base.baseUrl}/clinic/hospitalDoctor/queryDoctorPagePub`, data);
    },
	// POST/department/queryDepartmentPage PC---（分页）查询科室列表------ygt
	queryDepartmentPage(params) {
		return axios.post(`${base.baseUrl}/clinic/department/queryDepartmentPage`, params);
	},
	// GET /storeDevice/distance/deviceInfo  pc--药店下设备列表（距离从近到远）
	queryClientList(params) {
		return axios.get(`${base.baseUrl}/iotss/storeDevice/distance/deviceInfo?latitude=${params.latitude}&longitude=${params.longitude}&tenantId=${params.tenantId}`);
	},
}
    

export default home;