import axios from '@/api/http';
import base from '@/api/base';
const login = {
	//POST /iotss/user/sendPhoneCode 用户注册获取手机验证码
    registerWithPhonecode(params) {
        return axios.post(`${base.baseUrl}/iotss/user/sendPhoneCode`, params);
    },
	//POST /iotss/user/phoneRegister 用户手机号码,验证码,密码注册
    registerWithPhone(params) {
        return axios.post(`${base.baseUrl}/iotss/user/phoneRegister`, params);
    },
	//POST /iotss/user/login 用户名密码登录
    loginAuth(params) {
        return axios.post(`${base.baseUrl}/iotss/user/login`, params);
    },
	//POST /iotss/user/loginByPhoneCode 用户名验证码登录
    loginByPhoneCode(params) {
        return axios.post(`${base.baseUrl}/iotss/user/loginByPhoneCode`, params);
    },
  //POST /iotss/user/getUserByPhone 通过手机号获取用户
  getUserByPhone(params) {
    return axios.post(`${base.baseUrl}/iotss/user/getUserByPhone?type=${params.type}&phone=${params.phone}`);
  },

	//POST /iotss/user/updatePasswordByPhoneCode 通过手机验证码修改密码(无需登录). 密码修改完成后, 请退出登录状态(如果已经登录)
    updatePasswordByPhoneCode(params) {
        return axios.post(`${base.baseUrl}/iotss/user/updatePasswordByPhoneCode`, params);
    },
	//POST /iotss/user/getWxWebLoginUrl 获取微信网页登录(开放平台)地址, 入参url为登录完成后跳转的地址
    getWxWebLoginUrl(params) {
        return axios.post(`${base.baseUrl}/iotss/user/getWxWebLoginUrl`, params);
    },
	//POST /iotss/user/wxMpCodeLogin 通过微信登录后的code授权登录/这里如果没有绑定用户会创建一个新用户,用户名为:wechat_微信昵称拼音_随机数16位
    wxMpCodeLogin(params) {
        return axios.post(`${base.baseUrl}/iotss/user/wxMpCodeLogin`, params);
    },

}
export default login;
