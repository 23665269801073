import store from "@/store";
import { Message } from "element-ui";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/home.vue"),
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("../views/home/home.vue"),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/dashboard"),
      },
      // 个人中心
      {
        path: "/my",
        name: "my",
        component: () => import("../views/home/components/personalInfo"),
        children: [
          {
            path: "/my/myInfo",
            name: "myInfo",
            component: () => import("../views/home/components/myInfo"),
          },
          {
            path: "/my/myAvatar",
            name: "myAvatar",
            component: () => import("../views/home/components/myAvatar"),
          },
          {
            path: "/my/myNickname",
            name: "myNickname",
            component: () => import("../views/home/components/myNickname"),
          },
          // 就诊人列表
          {
            path: "/my/patientList",
            name: "patientList",
            component: () =>
              import("../views/Consultation/components/patientList"),
          },
          // 就诊人详情
          {
            path: "/my/patientDetails",
            name: "patientDetails",
            component: () =>
              import("../views/Consultation/components/patientDetails"),
          },
          // 查看就诊人
          {
            path: "/my/patientDetailsSee",
            name: "patientDetailsSee",
            component: () =>
              import("../views/Consultation/components/patientDetailsSee"),
          },
          // 我的关注
          {
            path: "/my/myFollow",
            name: "myFollow",
            component: () => import("../views/home/components/myFollow"),
          },
          // 我的收藏
          {
            path: "/my/myFavorite",
            name: "myFavorite",
            component: () => import("../views/home/components/myFavorite"),
          },
          // 问诊服务
          {
            path: "/my/consultService",
            name: "consultService",
            component: () => import("../views/home/components/consultService"),
            redirect: "/my/consultService/reservation",
            children: [
              // 我的预约
              {
                path: "/my/consultService/reservation",
                name: "reservation",
                component: () =>
                  import("../views/Consultation/components/reservation"),
              },
              // 问诊记录
              {
                path: "/my/consultService/consultRecord",
                name: "consultRecord",
                component: () =>
                  import("../views/Consultation/components/consultationRecord"),
              },
              // 历史处方笺
              {
                path: "/my/consultService/hisPrescription",
                name: "hisPrescription",
                component: () => import("../views/history"),
              },
            ],
          },
          // 问诊记录
          {
            path: "/my/consultationRecord",
            name: "consultationRecord",
            component: () =>
              import("../views/Consultation/components/consultationRecord"),
          },
          // 订单记录
          {
            path: "/my/myOrder",
            name: "myOrder",
            component: () => import("../views/healthMall/components/orderList"),
          },
          // 订单详情
          {
            path: "/my/myOrderDetail",
            name: "myOrderDetail",
            component: () =>
              import("../views/healthMall/components/orderDetails"),
          },
          // 商品-发表评价
          {
            path: "/my/publishComment",
            name: "publishComment",
            component: () =>
              import("../views/healthMall/components/publishComment"),
          },
          // 申请开票
          {
            path: "/my/invoicePage",
            name: "invoicePage",
            component: () =>
              import("../views/healthMall/components/invoicePage"),
          },
          // 地址管理
          {
            path: "/my/myAddress",
            name: "myAddress",
            component: () => import("../views/address"),
          },
          // 历史处方笺
          {
            path: "/my/history",
            name: "history",
            component: () => import("../views/history"),
          },
          // 健康档案
          {
            path: "/my/healthRecord",
            name: "healthRecord",
            component: () => import("../views/healthRecord"),
          },
          // 健康档案详情
          {
            path: "/my/healthDetail",
            name: "healthDetail",
            component: () => import("../views/healthRecord/healthDetail"),
          },
          // 设置健康档案标签
          {
            path: "/my/healthSetting",
            name: "healthSetting",
            component: () => import("../views/healthRecord/healthSetting"),
          },
          // 查看体检报告详情
          {
            path: "/my/reportDetail",
            name: "reportDetail",
            component: () => import("../views/healthRecord/reportDetail/reportDetail"),
          },
        ],
      },
      //在线问诊
      {
        path: "/Consultation",
        name: "Consultation",
        component: () => import("../views/Consultation"),
      },
      {
        path: "/docInfo",
        name: "docInfo",
        component: () => import("../views/Consultation/components/docInfo"),
      },
      {
        path: "/patientInfo",
        name: "patientInfo",
        component: () => import("../views/Consultation/components/patientInfo"),
      },
      {
        path: "/consult",
        name: "consult",
        component: () => import("../views/Consultation/components/consult"),
      },
      {
        path: "/medicinebox",
        name: "medicinebox",
        component: () => import("../views/Consultation/components/medicinebox"),
      },
      // 健康商城 start----------------
      {
        path: "/mall",
        name: "mall",
        component: () => import("../views/healthMall/mallPage"),
      },
      // 商品详情页
      {
        path: "/goodsDetails",
        name: "goodsDetails",
        component: () => import("../views/healthMall/components/goodsDetails"),
      },
      // 药店详情页
      {
        path: "/storeDetails",
        name: "storeDetails",
        component: () => import("../views/healthMall/components/storeDetails"),
      },

      // 购物车
      {
        path: "/shopCart",
        name: "shopCart",
        component: () => import("../views/healthMall/components/shopCart"),
      },
      // 反馈
      {
        path: "/feedback",
        name: "feedback",
        component: () => import("../views/healthMall/components/feedback"),
      },

      // 确认订单
      {
        path: "/confirmOrder",
        name: "confirmOrder",
        component: () => import("../views/healthMall/components/confirmOrder"),
      },
      // 支付订单
      {
        path: "/payOrder",
        name: "payOrder",
        component: () => import("../views/healthMall/components/payOrder"),
      },
      // 健康商城 end----------------
      // 家庭终端 =================
      {
        path: "/homeTerminal",
        name: "homeTerminal",
        component: () => import("../views/homeTerminal/homeTerminal"),
      },
      // 家庭终端 end =================

      //关于I LOVE 健康
      {
        path: "/health",
        name: "health",
        component: () => import("../views/health"),
      },
      //商品信息
      // {
      //   path: "/commodity",
      //   name: "commodity",
      //   component: () => import("../views/commodity"),
      // },
      //药品信息店铺信息
      // {
      //   path: "/ShopInformation",
      //   name: "ShopInformation",
      //   component: () => import("../views/ShopInformation"),
      // },
      //公司动态
      {
        path: "/trends",
        name: "trends",
        component: () => import("../views/trends"),
      },
      // 加入我们
      {
        path: "/Joinus",
        name: "Joinus",
        component: () => import("../views/Joinus"),
      },
      // 购物车
      // {
      //   path: "/shopping",
      //   name: "shopping",
      //   component: () => import("../views/shopping-弃用"),
      // },
      // 审核
      // {
      //   path:'/tocheck',
      //   name:'tocheck',
      //   component:()=>import('../views/shopping/tocheck')
      // },
      //支付订单
      // {
      //   path: "/pay/:address?/:arr?/:buyMedicineId?/:row?",
      //   name: "pay",
      //   component: () => import("../views/Payorder"),
      // },
      //支付
      // {
      //   path: "/payfor",
      //   name: "payfor",
      //   component: () => import("../views/Payorder/payfor"),
      // },
      // 公司动态列表
      {
        path: "/company",
        name: "company",
        component: () => import("../views/trends/company"),
      },
      //行业动态列表
      {
        path: "/industry",
        name: "industry",
        component: () => import("../views/trends/industry"),
      },
      // 公司动态内容
      {
        path: "/dynamicContent",
        name: "dynamicContent",
        component: () => import("../views/trends/dynamicContent"),
      },
      // 订单选择收货地址的页面
      {
        path: "/selectSite",
        name: "selectSite",
        component: () =>
          import("../views/healthMall/components/address/selectSite"),
      },
      // 订单添加收货地址的页面
      {
        path: "/addDeliveryAddress",
        name: "addDeliveryAddress",
        component: () =>
          import("../views/healthMall/components/address/addDeliveryAddress"),
      },
    ],
  },
  // 服务协议
  {
    path: "/serviceAgreement",
    name: "serviceAgreement",
    component: () => import("../views/serviceAgreement"),
  },
  // 免责申明
  {
    path: "/Disclaimer",
    name: "Disclaimer",
    component: () => import("../views/Disclaimer"),
  },
  // 隐私政策
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/privacy"),
  },

  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//解决退出登录后,报当前位置的冗余导航问题
const RouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch((err) => err);
};
const RouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch((err) => err);
};

//路由拦截，在跳转购物车页面和商品详情页面时，如果没登录提示先登录
const BlackList = [
  "/patientInfo",
  "/prescription", //基础页面
  "/shopCart",
  "/confirmOrder",
  "/payOrder", // 商城
  "/storeDetails",
  "/my",
  "/my/myInfo",
  "/my/myAvatar",
  "/my/myNickname", //个人信息
  "/my/patientList",
  "/my/patientDetails",
  "/my/patientDetailsSee",
  "/my/consultationRecord", //问诊
  "/my/consultService/reservation",
  "/my/consultService/consultRecord",
  "/my/consultService/hisPrescription",
  "/my/myOrder",
  "/my/myOrderDetail",
  "/my/publishComment",
  "/my/invoicePage",
  "/my/myAddress",
  "/my/history", //我的
  "/my/myFollow",
  "/my/myFavorite",
  "/my/healthRecord",
  "/my/healthDetail",
  "/my/healthSetting",
  //家庭终端
  // "/homeTerminal",
];
router.beforeEach((to, from, next) => {
  const token = store.state.token;
  if (BlackList.includes(to.path) && !token) {
    next("/dashboard");
    return Message("请先登录");
  } else {
    next();
  }
});

export default router;
