import axios from '@/api/http';
import base from '@/api/base';

const trends ={
    // 请求公司动态新闻列表
    queryDtoTrends(data){
        return axios.post(`${base.baseUrl}/iotss/news/page`, data);
    },

    // 请求公司动态新闻详情
    getTrends(data){
        return axios.post(`${base.baseUrl}/iotss/news/get`, data);
    }
    
}

export default trends;