/**
 * api接口的统一出口
 */
import login from '@/network/login'
import consult from '@/network/consult'
import upload from '@/network/upload'
import home from '@/network/home'
import trends from '@/network/trends'
import joinus from '@/network/joinus'
import record from '@/network/record'
import history from '@/network/history'
import address from '@/network/address'
import CommodityInformation from '@/network/CommodityInformation'
import patient from '@/network/patient'
import myInfo from '@/network/myInfo'
import mall from '@/network/mall'
import message from '@/network/message'
import report from "@/network/report";

// 导出接口
export default {
  login,
  consult,
  upload,
  home,
  trends,
  joinus,
  CommodityInformation,
  record,
  history,
  address,
  patient,
  myInfo,
  mall,
  message,
  report
}
