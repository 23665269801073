// official/selectAll

import axios from '@/api/http'
import base from '@/api/base'

const joinus = {

  // 请求招聘岗位列表
  queryOfficial (params) {
    return axios.get(`${base.baseUrl}/iotss/official/selectAll?pageNo=${params.pageNum}&pageSize=${params.pageSize}`)
  },
  // 请求职位
  queryPositionalTitlePage (params) {
    return axios.post(`${base.baseUrl}/clinic/positionalTitle/queryPositionalTitlePage`, params)
  },
  // 查询医院列表
  queryHospitalPage (params) {
    return axios.post(`${base.baseUrl}/clinic/hospital/queryHospitalPage`, params)
  },
  // 投递岗位
  addJobInsert (data) {
    return axios.post(`${base.baseUrl}/iotss/job/insert`, data)
  },
  // 加入我们
  joinUsApi (data) {
    return axios.post(`${base.baseUrl}/iotss/joinUs/create`, data)
  },
  //POST /iotss/user/sendPhoneCode 获取手机验证码
  sendPhonecode(params) {
    return axios.post(`${base.baseUrl}/iotss/user/sendPhoneCode`, params);
  },

}

export default joinus
