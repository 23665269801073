import axios from '@/api/http';
import base from '@/api/base';


export default {
  // 查询报告列表
  reportList(params){
    return axios.post(`${base.baseUrl}/clinic/patientDetection/report`, params);
  },
// 获取报告详情
  reportDetail(params){
    return axios({
      url: `${base.baseUrl}/clinic/patientDetection/reportDetail`,
      method: "get",
      params
    })
  },
}

