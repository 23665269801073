import axios from "@/api/http";
import base from "@/api/base";

export default {
  //用户端查看消息列表
  queryDoctorClinicDoctorList(query, params) {
    return axios.post(
      `${base.baseUrl}/clinic/doctorClinic/queryDoctorClinicDoctorList?userId=${query.userId}&doctorName=${query.doctorName}`,
      params
    );
  },
};
