   // store/historySearch.js
   let lifeData = localStorage.getItem('myStroage') ? JSON.parse(localStorage.getItem('myStroage')) : {};
   const state = {
     searchHistory: lifeData.searchHistory || [], // 初始化为空数组
   };

   const mutations = {
     ADD_SEARCH_HISTORY(state, query) {
       if (state.searchHistory.includes(query)) {
         state.searchHistory.splice(state.searchHistory.indexOf(query), 1);
       }
       state.searchHistory.unshift(query);
       // 限制历史记录数量，例如只保留最近10个搜索
       if (state.searchHistory.length > 10) {
         state.searchHistory.pop();
       }
     },
     REMOVE_SEARCH_HISTORY_ITEM(state, index) {
       state.searchHistory.splice(index, 1);
     },
     SET_SEARCH_HISTORY(state, searchHistory) {
       console.log('searchHistory',searchHistory)
       state.searchHistory = searchHistory;
     },
   };

   const actions = {
     addSearchHistory({ commit }, query) {
       commit('ADD_SEARCH_HISTORY', query);
     },
     async clearSearchHistory({ commit }) {
       commit('SET_SEARCH_HISTORY', []);
     },
   };

   export default {
     namespaced: true, // 使用命名空间
     state,
     mutations,
     actions,
   };
