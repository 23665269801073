import axios from '@/api/http';
import base from '@/api/base';

const patient ={
  //GET /clinic/patient/getPatientList 获取当前用户下所有就诊人
  getPatientList(params){
    return axios.get(`${base.baseUrl}/clinic/patient/getPatientList`, params);
  },
  //POST /clinic/patient/addPatient 新增就诊人信息
  addPatient(params){
    return axios.post(`${base.baseUrl}/clinic/patient/addPatient?userId=${params.userId}`, params);
  },
  //POST /clinic/patient/updatePatient 修改就诊人信息
  updatePatient(params){
    return axios.post(`${base.baseUrl}/clinic/patient/updatePatient`, params);
  },
  //POST /clinic/patient/selectByPrimaryKey/{患者ID} 获取就诊人详情
  selectByPrimaryKeyByPatientId(patientId){
    return axios.post(`${base.baseUrl}/clinic/patient/selectByPrimaryKey/${patientId}`);
  },
  //POST /clinic/patient/unBindPatient 解绑就诊人
  unBindPatient(params){
    return axios.post(`${base.baseUrl}/clinic/patient/unBindPatient?userId=${params.userId}`, params);
  },
  //健康记录
  queryPatientDetectionPage(params){
    return axios.post(`${base.baseUrl}/clinic/patientDetection/queryPatientDetectionPage`);
  },
  //查询患者健康档案信息
  queryHealthRecord(params){
    return axios.get(`${base.baseUrl}/clinic/healthrecord/queryHealthRecord?patientId=${params.patientId}`);
  },
  //查询健康档案标签库列表
  queryHealthRecordLabelList(params){
    return axios.get(`${base.baseUrl}/clinic/healthrecord/queryHealthRecordLabelList`);
  },
  //保存健康档案基本信息
  saveBaseInfo(params){
    return axios.post(`${base.baseUrl}/clinic/healthrecord/saveBaseInfo`, params);
  },
}
export default patient;
