// import TencentCloudChat from '@tencentcloud/chat'
import TencentCloudChat from "@tencentcloud/chat/index.es.js"
import eventHub from '@/utils/eventHub'
import $store from '@/store'
import base from '@/api/base'
import { im_setMessageRead } from '@/common/js/IM-message'
//IM信令消息处理


export function onSignalingListener() {
  //发现不监听信令，信令发生会失败。这里必须监听一下信令
  TIMchat.addSignalingListener(TencentCloudChat.TSignaling.NEW_INVITATION_RECEIVED, onNewInvitationReceived);

  TIMchat.addSignalingListener(TencentCloudChat.TSignaling.INVITEE_ACCEPTED, onInviteeAccepted);

  TIMchat.addSignalingListener(TencentCloudChat.TSignaling.INVITEE_REJECTED, onInviteeRejected);

  TIMchat.addSignalingListener(TencentCloudChat.TSignaling.INVITATION_CANCELLED, onInvitationCancelled);

  TIMchat.addSignalingListener(TencentCloudChat.TSignaling.INVITATION_TIMEOUT, onInvitationTimeout);
}
export function offSignalingListener() {
  TIMchat.removeSignalingListener(TencentCloudChat.TSignaling.NEW_INVITATION_RECEIVED, onNewInvitationReceived);

  TIMchat.removeSignalingListener(TencentCloudChat.TSignaling.INVITEE_ACCEPTED, onInviteeAccepted);
  TIMchat.removeSignalingListener(TencentCloudChat.TSignaling.INVITEE_REJECTED, onInviteeRejected);
  TIMchat.removeSignalingListener(TencentCloudChat.TSignaling.INVITATION_CANCELLED, onInvitationCancelled);

  TIMchat.removeSignalingListener(TencentCloudChat.TSignaling.INVITATION_TIMEOUT, onInvitationTimeout);
}

//收到新的邀请
function onNewInvitationReceived(event) {
  console.log('onNewInvitationReceived:::::::::::::::::::::::::::::::::::::::::::',event);
  //排除自己邀请自己的请求（出现在其他端发起时，web会收到自己邀请自己的信令）
  if(event.data.inviter === base.IM_prefix + $store.state.user.user_info.id){
    return
  }
  console.log('收到了:::::邀请')
  console.log('是否忙线::::', $store.state.chat.isBusyLine)
  //收到一个来电请求，判断自己当前是否在通话中。如果是在通话中则直接回复对方忙线。
  let signaling = event.data;
  parse_data(signaling)
  if ($store.state.chat.isBusyLine) {
    //用信令拒绝并回复对方忙线
    setTimeout(() => {
      sendSignaling_busy(signaling)
    }, 1000)
  } else {
    /*
    type: 8, //7语音8视频通话
    strRoomId: '',
    roomId: 0,
    toUserId: '',//通话对方的id
    */
    let payload = {
      type: signaling.data.type, //7语音8视频通话
      roomId: signaling.data.roomId,
      toUserId: signaling.inviter, //通话对方的id
      inviteID: signaling.inviteID
    }
    $store.commit('chat/set_trtcOptions', payload)
    $store.commit('chat/set_trtc_signaling', signaling)
    console.log('$store.state.chat.trtcOptions:::', $store.state.chat.trtcOptions);
    console.log('$store.state.chat.trtc_signaling:::', $store.state.chat.trtc_signaling);
    //跳转到音视频接听界面
    $store.commit('chat/set_trtcShow_call', true)
  }
}
//被邀请人接受了邀请
function onInviteeAccepted(event) {
  // console.log('onInviteeAccepted:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::', event);
  eventHub.$emit("im_signaling_trtc", event);
}
//被邀请人拒绝了邀请
function onInviteeRejected(event) {
  // console.log('onInviteeRejected:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::', event);
  eventHub.$emit("im_signaling_trtc", event);
}
//邀请被发起者取消
function onInvitationCancelled(event) {
  // console.log('onInvitationCancelled:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::', event);
  eventHub.$emit("im_signaling_trtc", event);
}
//邀请超时
function onInvitationTimeout(event) {
  // console.log('onInvitationTimeout:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::', event);
  //这里处理音视频通话邀请超时
    eventHub.$emit("im_signaling_trtc", event);
}

export function parse_data(data) {
  try {
    //解析data里面的data
    data.data = JSON.parse(data.data)
  } catch (e) {}
}


function sendSignaling_busy(signaling) {
  console.log('收到的内容::::', signaling);
  console.log('signaling.inviteID::::', signaling.inviteID);

  let promise = TIMchat.reject({
    inviteID: signaling.inviteID,
    data: JSON.stringify({
      // excludeFromHistoryMessage: true, //表示此信令消息不更新会话 unreadCount 和 lastMessage
      busy: 1, //通知对方忙线
    })
  })
  promise.then((imResponse) => {
    console.log('发送 OK', imResponse)
    im_setMessageRead(imResponse.data.message.conversationID) //设置已读
  }).catch((error) => {
    console.warn('发送 failed:', error)
  })
}
