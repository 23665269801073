<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import ANIMATE from '@/utils/wow.min'
new ANIMATE.WOW().init()
import { api_getSdkAppId } from '@/common/js/userJs'
export default {
  name: 'App',
  mounted () {
    // 获取SKDAPPID
    api_getSdkAppId()
  }
}
</script>

<style lang="scss">
	html{
		scroll-behavior: smooth;
	}
  .el-popover-notification{
    padding: 0 !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: hidden;
  }
  .el-loading-mask{
    z-index: 99;
  }
</style>
