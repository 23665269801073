import $store from '@/store'
import $router from '@/router'
import $base from '@/api/base'
import api from '@/api' // 导入api
//测试阶段，前端生成userSig
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js'

import {  Message } from 'element-ui'
import { createUserSig, getSdkAppId } from '@/network/chat'
import { im_init,im_handleEvents } from '@/common/js/IM' //引入IM，创建IM实例并监听事件

export function imLogin(userId) {
  //获取userSig 登录IM
  // test_get_usig(userId) //测试
  let _trtcOptions = $store.state.chat.trtcOptions
  // 开始登录
  TIMchat.login({
    userID: _trtcOptions.userId,
    userSig: _trtcOptions.userSig
  }).then(imResponse => {
    console.log('im登录成:::')
    //如果IM登录成功，则更新一下自己的IM个人信息
    setTimeout(() => {
      im_updateMyProfile()
    }, 500)
  }).catch((Error) => {
    // console.warn('Error:', Error)
  })
}

export function imLogout() {
  $store.commit('chat/set_chatShow', false)
  $store.commit('chat/set_trtcShow', false)
  $store.commit('chat/set_trtcShow_call', false)
  TIMchat.logout().then(imResponse => {
    console.log('登出成功:::')
  }).catch(err => {
    console.warn('imLogout::', err)
  })
}

//前端生成，后期需改为接口获取----测试
function test_get_usig(userId) {
  // 生成 userSig
  const userSigGenerator = new LibGenerateTestUserSig($base.sdkAppId, $base.secretKey, 604800)
  userId = 'user' + userId
  let userSig = userSigGenerator.genTestUserSig(userId)
  let payload = {
    sdkAppId: $base.sdkAppId,
    userSig: userSig,
    userId: userId
  }
  console.log('trtc::::70::', payload)
  $store.commit('chat/set_trtcOptions', payload)
}


export function im_updateMyProfile(userInfo) {
  // 修改个人标配资料
  try {
    console.log('更新IM个人信息:::', $store.state.user.user_info)
    userInfo = $store.state.user.user_info
    let promise = TIMchat.updateMyProfile({
      nick: userInfo.nickName || userInfo.id,
      avatar: userInfo.avatarUrl || '',
      role: 4, //role 1 医师 2 药师 3 导诊 4 用户
    })
    promise.then(function(imResponse) {
      console.log(imResponse.data) // 更新资料成功
    }).catch(function(imError) {
      console.warn('updateMyProfile error:', imError) // 更新资料失败的相关信息
    })
  } catch (e) {

  }

}

export function api_getSdkAppId() {
  getSdkAppId().then((res) => {
    if (res.status === 200) {
      let sdkAppId = res.data.data;
      let payload = {
        sdkAppId: sdkAppId
      }
      $store.commit('chat/set_trtcOptions', payload)
      im_init()
      im_handleEvents()

      let uid = $store.state.user.user_info.id;
      if(uid){
        setTimeout(() => {
          api_createUserSig_imLogin()
        },200)
      }

    } else {
      Message({
        message: res.message,
        type: 'error',
      })
    }
  }).catch((Error) => {
    // console.warn('Error:', Error)
  })
}

export function api_createUserSig_imLogin() {
  let uid = $store.state.user.user_info.id;
  let params = {
    userId: $base.IM_prefix + uid
  }
  createUserSig(params).then((res) => {
    if (res.status === 200) {
      let payload = {
        userSig: res.data.data,
        userId: params.userId
      }
      $store.commit('chat/set_trtcOptions', payload)
      imLogin()
    } else {
      Message({
        message: res.message,
        type: 'error',
      })
    }
  }).catch((Error) => {
    // console.warn('Error:', Error)
  })
}
