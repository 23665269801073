import {
  extractProperties
} from '@/utils'
import {
  parse_msg
} from '@/common/js/IM-message'
import $store from '@/store'

const state = {
  chatShow: false, //聊天列表展示
  trtcShow_call: false, //音视频通话界面 接听界面
  trtcShow: false, //音视频通话界面
  trtcMini: false, //音视频小窗
  unreadCount: 0, //总未读消息数目
  trtcOptions: {
    type: 8, //7语音8视频通话
    sdkAppId: '',
    userSig: '',
    userId: '', //user+id
    roomId: 0, //目前roomId就是strRoomId
    inviteID: '', //当前音视频信令
    toUserId: '', //通话对方的id
  },
  isBusyLine: false, //忙线中(当进入音视频通话中时改为true)
  trtc_signaling: {}, //当前接听得trtc信令
  toUserId: '', //消息发送to【与音视频通话对方隔开】
  currentUserProfile: {}, //当前用户信息


  //聊天数据
  allConversation: [], // 所有的conversation
  conversationActive: {}, //聊天进行中的会话
  currentConversationID: '', // 当前聊天对话ID
  currentConversation: {}, // 当前聊天对话信息
  currentMessageList: [], // 当前聊天消息列表

  nextReqMessageID: '', // 下一条消息标志
  isCompleted: false, // 当前会话消息是否已经请求完毕
  selectedMember: [], // 选中的群成员

  //选择位置消息 参数
  address_params: {},
  pickAds_Dialog: false,

  //IM
  isSDKReady: false, //

  //设备控制
  videoStatus: true,
  voiceStatus: true,
  speakStatus: true,

  //审核咨询
  consultConversationID: null,
  currentConsultDrugs: {
    drugId: '',
    storeId: '',
    deviceId: ''
  },

  //登录弹窗
  loginModal: false
}

const mutations = {
  set_loginModal: (state, payload) => {
    state.loginModal = payload
  },
  set_allConversation: (state, payload) => {
    state.allConversation = payload
  },
  set_sdkReady: (state, payload) => {
    state.isSDKReady = payload
  },
  set_chatShow: (state, payload) => {
    state.chatShow = payload
    // if (window.location.pathname != '/consult') {
    // }
  },
  set_trtcShow: (state, payload) => {
    state.trtcShow = payload
  },
  set_unreadCount: (state, payload) => {
    state.unreadCount = payload
  },
  set_trtcShow_call: (state, payload) => {
    state.trtcShow_call = payload
  },
  set_trtcOptions: (state, payload) => {
    state.trtcOptions = extractProperties(state.trtcOptions, payload)
  },
  set_currentUserProfile: (state, payload) => {
    state.currentUserProfile = payload
  },
  set_trtc_signaling: (state, payload) => {
    state.trtc_signaling = payload
  },
  set_trtcMini: (state, payload) => {
    state.trtcMini = payload
  },
  set_address_params: (state, payload) => {
    state.address_params = payload
  },
  set_pickAds_Dialog: (state, payload) => {
    state.pickAds_Dialog = payload
  },
  set_videoStatus: (state, payload) => {
    state.videoStatus = payload
  },
  set_voiceStatus: (state, payload) => {
    state.voiceStatus = payload
  },
  set_speakStatus: (state, payload) => {
    state.speakStatus = payload
  },
  set_currentConsultDrugs: (state, payload) => {
    state.currentConsultDrugs = payload
  },
  set_consultConversationID: (state, payload) => {
    state.consultConversationID = payload
  },
  //重置当前会话
  resetCurrentConversation: (state) => {
    state.conversationActive = {}
    state.currentConversation = {} // 当前聊天对话信息
    state.currentMessageList = [] // 当前聊天消息列表
    state.isCompleted = false // 当前会话消息是否已经请求完毕

  },
  set_cliclItem: (state, payload) => {
    console.log('选中::', payload)
    state.conversationActive = payload
    //获取当前会话记录
  },
  // 收到、发送
  receiveMessage(state, messageList) {
    // 筛选出当前会话的消息
    //如果是收到的消息，是数组；如果是自己发送的消息，返回的是对象
    if (Array.isArray(messageList)) {
      // 筛选出当前会话的消息
      const result = messageList.filter(item => item.conversationID === state.conversationActive.conversationID)
      parse_msg(result)
      state.currentMessageList = [...state.currentMessageList, ...result]
    } else if (messageList.conversationID === state.conversationActive.conversationID) {
      //处理自己发送的消息
      parse_msg(messageList)
      state.currentMessageList = [...state.currentMessageList, messageList]
    }
  },
  receiveMessageModified(state, messageList) {
    if (Array.isArray(messageList)) {
      // 筛选出当前会话的消息
      const result = messageList.filter(item => item.conversationID === state.conversationActive.conversationID)
      result.forEach((item, index) => {
        parse_msg(item)
        //替换聊天里的
        // 找到索引
        let _index = state.currentMessageList.findIndex(message => message.ID === item.ID)
        if (_index !== -1) {
          state.currentMessageList.splice(_index, 1, item)
        }
      })
    }
  },
  set_currentMessageList(state, payload) {
    if (payload.messageList) {
      // payload.messageList = parse_msg(payload.messageList)
      //解析消息。传递对象的引用，函数内改变对象，原对象也会跟着改变，因为这里没再次赋值
      parse_msg(payload.messageList)
    }
    state.currentMessageList = [...payload.messageList, ...state.currentMessageList]
    state.isCompleted = payload.isCompleted
    state.nextReqMessageID = payload.nextReqMessageID
  },
  set_isBusyLine: (state, payload) => {
    state.isBusyLine = payload
  }

}

const actions = {
  /*  //自己发送消息
   sendMessage({ commit }, message) {
     // state.currentMessageList.push(message)
     return new Promise((resolve, reject) => {
       TIMchat.sendMessage(message).then(imResponse => {
         //将消息推入聊天
         console.log('发送成功::::', imResponse)
         commit('receiveMessage', [imResponse.data.message])
         resolve(imResponse)
       }).catch(error => {
         reject(error)
       })
     })
   } */
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
