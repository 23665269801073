import axios from '@/api/http'
import base from '@/api/base'

const history = {

  // 请求处方笺记录
  gethistoryList (data) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/pageMyDoctorClinicPrescriptionDTO`, data)
  },
  doctorClinicPrescriptionPage (data) {
    return axios.post(`${base.baseUrl}/clinic/doctorClinicPrescription/doctorClinicPrescriptionPage`,data)
  },
  // 请求原始处方笺详情
  getOraHistoryDetail (prescriptionId) {
    return axios.get(`${base.baseUrl}/clinic/doctorClinicPrescription/getOriginalPrescriptionData?prescriptionId=${prescriptionId}`)
  }
}

export default history
