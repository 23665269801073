import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api' // 导入api
import { api_getSdkAppId } from '@/common/js/userJs'


//全局mixin
import G_mixin from './store/$G.mixin.js'
Vue.mixin(G_mixin);

import '@/assets/css/index.css'
import '@/assets/css/base.css'
// 引入vue-amap
import VueAMap from 'vue-amap'
// import './icon/iconfont.css'
import '@/assets/animate/styles/csshake.css'
import "@/assets/css/animateCss3.css";
// 引入字体图标
import "@/assets/iconfont/iconfont.css";
// 字体图标svg格式
import "@/assets/iconfont/iconfont.js"

import qs from 'qs'

import test from '@/utils/test.js'
Vue.prototype.$test = test;

Vue.prototype.$qs = qs;
import $ from 'jquery'

import * as moment from 'moment';
Vue.prototype.$moment = moment;

Vue.prototype.$api = api;
//WebSocket封装方法
import newSocket from './api/webSocket.js';
Vue.prototype.$newSocket = newSocket;
import TRTC from 'trtc-js-sdk';
Vue.prototype.$TRTC = TRTC;


import ElementUI from 'element-ui';///------------引入element
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
ElementUI.Dialog.props.closeOnClickModal.default = false; //点击弹框外不消失




import VueAreaLinkage from 'vue-area-linkage'
import 'vue-area-linkage/dist/index.css'
Vue.use(VueAreaLinkage)
Vue.use(VueAMap)
// 初始化vue-amap
VueAMap.initAMapApiLoader({
	// 高德的key
	key: '4fe67fcb69dbaeaa46527d906a4f8708',
	// 插件集合
	plugin: [
		'AMap.CircleEditor',// 圆形编辑器插件
		"AMap.Geolocation", // 定位控件，用来获取和展示用户主机所在的经纬度位置
		"AMap.Geocoder", // 地理编码与逆地理编码服务，用于地址描述与坐标间的相互转换
		"AMap.Autocomplete",
		"AMap.PlaceSearch",
		"AMap.CitySearch",
	],
	// 高德 sdk 版本，默认为 1.4.4
	v: '1.4.4'
});
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:'2e683a6e818a7a9780424a5bc5b56d38',
}


// import './utils/shipei'

Vue.config.productionTip = false

Vue.filter('phoneStart', (value) => {
  if (!value) return ''
  const reg = /(\d{3})\d*(\d{4})/
  let str = value
  str = str.replace(reg, '$1****$2')
  return str
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
