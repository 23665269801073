let lifeData = localStorage.getItem('myStroage') ? JSON.parse(localStorage.getItem('myStroage')) : {};

const state = {
  user_info: lifeData.user_info || {},
  location: lifeData.location || {},
}

const mutations = {
    SET_user_info: (state, user_info) => {
      state.user_info = user_info
      //适配老代码
      console.log('适配老代码::::')
      sessionStorage.setItem('userId', user_info.id)
    },
    SET_location: (state, payload) => {
      state.location = payload
    }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
