const state = {
  app_test: '测试vuex',
  pay_order_form: {}
}

const mutations = {
  SET_app_test: (state, payload) => {
    state.app_test = payload
  },
  SET_pay_order_form: (state, payload) => {
    state.pay_order_form = payload
  }
}

const actions = {}

export default {
  namespaced: true,//启用命名空间，限定当前模块的mutations、actions、getters的名称不与其他模块冲突
  state,
  mutations,
  actions
}
