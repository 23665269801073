import axios from '@/api/http';
import base from '@/api/base';


const record ={
    // 查询订单列表
    queryRecordList(params){
        return axios.get(`${base.baseUrl}/clinic/buyMedicineOrder/page?pageNo=${params.pageNo}&pageSize=${params.pageSize}&isPaid=${params.isPaid}`);
    },
    //   查询订单列表详情
      queryRecordDetail(params){
        return axios.get(`${base.baseUrl}/clinic/buyMedicineOrder/page`,{params});
    },
}

export default record;